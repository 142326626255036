import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Index, Highlight, Configure, useSearchBox, useHits, UseHitsProps } from 'react-instantsearch-hooks-web';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { SearchBoxProps } from 'react-instantsearch-hooks-web';
import { atom, SetStateAction, useAtom } from 'jotai';
import type { UseSearchBoxProps } from 'react-instantsearch-hooks-web';
import { ChevronDown, Plus, Search, XIcon } from '@components/icons';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { useWindowSize } from '@lib/hooks/useWindowSize';
import { mobileSearchHeader } from '../Navbar';
import { useDebounce } from '@lib/hooks/useDebounce';
import { useMobileMediaQuery } from '@lib/media';
import { AnyEventObject, BaseActionObject, MarkAllImplementationsAsProvided, ResolveTypegenMeta, ServiceMap, State } from 'xstate';
import { Typegen0 } from '@lib/machine.typegen';
import { useActor, useMachine } from '@xstate/react';
import { AutoSearchBoxMachine } from '@lib/machines/AutoSearchBoxMachine/AutoSearchBox.machine';
import { useAutoSearchBoxActions, useAutoSearchBoxMatches } from '@lib/machines/AutoSearchBoxMachine/useAutoSearchBox';
import AutoCompleteProductsHit from './AutoCompleteProductsHit';
import AutoCompleteCollectionHit from './AutoCompleteCollectionHit';
import { productEmpty } from './AutoCompleteProductsHit/AutoCompleteProductsHit';
import { collectionEmpty } from './AutoCompleteCollectionHit/AutoCompleteCollectionHit';



const searchClient = algoliasearch(
  "9CX46VA6A6",
  "3801c683f5882d5babcb32f6d9877cff"
);


const customSearchQuery = atom('')
const customSearchFocus = atom(false)


export const searchMenuState = atom(false)
export const menuShadowState = atom(false)
export const menuCloseAction = atom(true)



interface ICustomSearchBox {
  clickSearchIconInside: () => State<unknown, AnyEventObject, any, {
    value: any;
    context: unknown;
  }, MarkAllImplementationsAsProvided<ResolveTypegenMeta<Typegen0, AnyEventObject, BaseActionObject, ServiceMap>>>,
  closeModal: () => State<unknown, AnyEventObject, any, {
    value: any;
    context: unknown;
  }, MarkAllImplementationsAsProvided<ResolveTypegenMeta<Typegen0, AnyEventObject, BaseActionObject, ServiceMap>>>

  isMobile: boolean,
  inputBoxClick: () => State<unknown, AnyEventObject, any, {
    value: any;
    context: unknown;
  }, MarkAllImplementationsAsProvided<ResolveTypegenMeta<Typegen0, AnyEventObject, BaseActionObject, ServiceMap>>>,
  clickSearchIcon: () => State<unknown, AnyEventObject, any, {
    value: any;
    context: unknown;
  }, MarkAllImplementationsAsProvided<ResolveTypegenMeta<Typegen0, AnyEventObject, BaseActionObject, ServiceMap>>>,
  isModelOpen: boolean,
  props?: UseSearchBoxProps,
}


export const AutoComplete = () => {




  const { width } = useWindowSize()


  const isMobile = width ? (width < 768) : false

  const {
    clickOutside,
    closeModal,
    clickInsideModal,
    clickSearchIcon,
    inputBoxClick,
    clickSearchIconInside,
  } = useAutoSearchBoxActions()
  const {
    isModelOpen,
    displayMenuItems,
    isDefaultState,
    inputRef
  } = useAutoSearchBoxMatches()



  const [searchQuery] = useAtom(customSearchQuery);
  const [isProductEmpty] = useAtom(productEmpty)
  const [isCollectionEmpty] = useAtom(collectionEmpty)

  // const isQueryEmpty: boolean = searchQuery.length === 0
  // const isProductHidden: boolean = false
  // const isCollectionHidden: boolean = false
  // const isPopularHidden: boolean = false
  const isQueryEmpty: boolean = searchQuery.length === 0
  const isProductHidden: boolean = isProductEmpty || isQueryEmpty
  const isCollectionHidden: boolean = isCollectionEmpty || isQueryEmpty
  const isPopularHidden: boolean = (isProductEmpty && isCollectionEmpty) || isQueryEmpty




  return (
    <InstantSearch searchClient={searchClient} indexName="DB_main" stalledSearchDelay={1000} >
      {/* <div className='bg-white w-full flex justify-center '> */}
      {isModelOpen &&
        <div className={`${isModelOpen ? "" : "hidden"} fixed z-[200] inset-0 bg-white w-full h-[100vh] phoneFin:hidden`}>
          {/* <div className='searchBoxWrapper relative  z-[200]'> */}
          <CustomSearchBox
            closeModal={closeModal}
            isMobile={isMobile}
            inputBoxClick={inputBoxClick}
            clickSearchIcon={clickSearchIcon}
            clickSearchIconInside={clickSearchIconInside}
            isModelOpen={isModelOpen}
          />

          <div className={` ${isMobile ? "flex flex-col w-full  h-full" : "absolute  -translate-x-12 block w-[400px] "}
          mt-[9px] pt-2 overflow-y-auto bg-white ${isModelOpen ? "block" : "hidden"}`}>
            <Index indexName='collections_main' >
              <div className={`${isMobile ? "px-9" : "px-10"}   ${isCollectionHidden ? "hidden" : ""} h-auto  py-4 `} >
                <div className='text-[#7e7e7e] font-semibold text-sm '>Koleksiyonlar</div>
                <Configure hitsPerPage={4} />
                <AutoCompleteCollectionHit />
              </div>

            </Index>
            <div className={` ${isMobile ? "px-9" : "px-10"} pt-2 pb-3 ${isProductHidden ? "hidden" : ""}`}>
              <div className='text-[#7e7e7e] font-semibold text-sm mb-2 '>Ürünler</div>
              <Configure hitsPerPage={4} />

              <AutoCompleteProductsHit />

            </div>
            <div className={`${isMobile ? "px-9" : "px-10"} pt-2 pb-3 ${(isPopularHidden) ? "" : "hidden"}`}>
              <div className='text-[#7e7e7e] font-semibold text-sm mb-2 '>
                Populer Arama Terimleri
              </div>
              <ul className='w-full h-fit flex gap-2 flex-wrap py-2'>
                <li className='h-full flex'>
                  <Link href={`/collections/collection`} >
                    <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal}>Çok Satanlar</a>
                  </Link>
                </li>
                <li className='h-full flex'>
                  <Link href={`/collections/wedding-dress`}>
                    <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Düğün Elbiseleri</a>
                  </Link>
                </li>
                <li className='h-full flex'>
                  <Link href={`/collections/new`}>
                    <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Yeni Gelenler</a>
                  </Link>
                </li>
                <li className='h-full flex'>
                  <Link href={`/collections/summer-2022`}>
                    <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Summer 2022</a>
                  </Link>
                </li>
                <li className='h-full flex'>
                  <Link href={`/collections/mezuniyet`}>
                    <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Mezüniyet Elbisesi</a>
                  </Link>
                </li>
              </ul>
            </div>

          </div>

        </div>
      }
      <div className='hidden  phone phoneFin:block relative z-[200] '>
        {/* <div className='searchBoxWrapper relative  z-[200]'> */}
        <CustomSearchBox
          closeModal={closeModal}
          isMobile={isMobile}
          inputBoxClick={inputBoxClick}
          clickSearchIcon={clickSearchIcon}
          clickSearchIconInside={clickSearchIconInside}
          isModelOpen={isModelOpen}
        />

        <div className={` ${isMobile ? "flex flex-col w-full  h-full" : "absolute  -translate-x-12 block w-[400px] "}
          mt-[9px] pt-2 overflow-y-auto bg-white ${isModelOpen ? "block" : "hidden"}`}>
          <Index indexName='collections_main' >
            <div className={`${isMobile ? "px-9" : "px-10"}   ${isCollectionHidden ? "hidden" : ""}  py-4 `} >
              <div className='text-[#7e7e7e] font-semibold text-sm '>Koleksiyonlar</div>
              <Configure hitsPerPage={4} />
              <AutoCompleteCollectionHit />
            </div>

          </Index>
          <div className={` ${isMobile ? "px-9" : "px-10"} pt-2 pb-3 ${isProductHidden ? "hidden" : ""}`}>
            <div className='text-[#7e7e7e] font-semibold text-sm mb-2 '>Ürünler</div>
            <Configure hitsPerPage={4} />

            <AutoCompleteProductsHit />

          </div>
          <div className={`${isMobile ? "px-9" : "px-10"} pt-2 pb-3 ${(isPopularHidden) ? "" : "hidden"}`}>
            <div className='text-[#7e7e7e] font-semibold text-sm mb-2 '>
              Populer Arama Terimleri
            </div>
            <ul className='w-full h-fit flex gap-2 flex-wrap py-2'>
              <li className='h-full flex'>
                <Link href={`/collections/collection`} >
                  <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal}>Çok Satanlar</a>
                </Link>
              </li>
              <li className='h-full flex'>
                <Link href={`/collections/wedding-dress`}>
                  <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Düğün Elbiseleri</a>
                </Link>
              </li>
              <li className='h-full flex'>
                <Link href={`/collections/new`}>
                  <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Yeni Gelenler</a>
                </Link>
              </li>
              <li className='h-full flex'>
                <Link href={`/collections/summer-2022`}>
                  <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Summer 2022</a>
                </Link>
              </li>
              <li className='h-full flex'>
                <Link href={`/collections/menuniyet`}>
                  <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal} >Mezüniyet Elbisesi</a>
                </Link>
              </li>
            </ul>
          </div>

        </div>

      </div>


      {/* </div> */}

    </InstantSearch>

  )
}



function CustomSearchBox({ closeModal, isMobile, inputBoxClick, clickSearchIcon, props, isModelOpen, clickSearchIconInside }: ICustomSearchBox) {
  const { query, refine, isSearchStalled, clear } = useSearchBox(props);

  const {

    inputRef
  } = useAutoSearchBoxMatches()



  const [searchQuery, setSearchQuery] = useAtom(customSearchQuery);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearchQuery(e.target.value)
    refine(e.target.value)
  }
  const handleClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setSearchQuery("")
    clear()
    inputRef.current?.focus()
  }



  const localRef = useRef<any>(null)

  useEffect(() => {

    if (inputRef.current.focus()) {
      localRef.current.focus()

    }
  }, [inputRef])





  return <>
    <div className={`${isMobile ? "w-full flex px-6 py-2" : "w-[280px]"}`}>
      <form
        noValidate className='text-base font-normal w-full flex  justify-between bg-[#efefef]  rounded-3xl  focus:border-2  focus:outline-none focus:shadow-outline-gray focus:border-[#949494]'>
        <button className="outline-none " title="Submit the search query." type="button" onClick={isModelOpen ? clickSearchIconInside : clickSearchIcon}>
          <Search className="w-6 h-6 text-black mx-4 " />
        </button >

        <input
          ref={inputRef}
          value={searchQuery} onChange={handleChange}
          onClick={inputBoxClick}
          className="placeholder:text-gray-400 bg-[#efefef] py-4 
          mr-6 w-full h-[37px] focus:outline-none" placeholder="Arama Yap"
          type="search" autoCorrect='off' autoCapitalize="off"
          spellCheck="false" maxLength={240} autoFocus ></input>

        <button onClick={(e) => handleClear(e)} className="outline-none "><XIcon className={`w-6 h-6 text-black mx-4 ${searchQuery.length > 0 ? "" : "hidden"}`} ></XIcon></button>
      </form>
      {isMobile && <button onClick={closeModal} className="outline-none w-10-h-10 ml-3 p-2 flex items-center justify-center bg-[#efefef] hover:bg-gray-200 rounded-full "><XIcon className={`w-6 h-6 text-[#333] `} ></XIcon></button>}
    </div>
  </>;
}



const transformItems: UseHitsProps['transformItems'] = (items, { results }) => {

  console.log("items,results", items, results);

  return items
};

function CustomCnm() {
  const hitsApi = useHits({
    transformItems,
  });

  console.log("hitsApi", hitsApi);

  return <><pre>{JSON.stringify(hitsApi, null, 2)}</pre></>;
}





// interface ProductsHitProps {
//   clickInsideModal: () => State<unknown, AnyEventObject, any, {
//     value: any;
//     context: unknown;
//   }, MarkAllImplementationsAsProvided<ResolveTypegenMeta<Typegen0, AnyEventObject, BaseActionObject, ServiceMap>>>,
//   hit: any,
//   props?: any,
// }
// function ProductsHit({ hit, clickInsideModal }: ProductsHitProps) {
//   if (!hit || !clickInsideModal) {
//     return null
//   }

//   return (
//     <Link href={`/products/${hit.objectID}`} >
//       <a className='cursor-pointer flex rounded-md items-center py-2 pl-[14px] pr-5 hover:bg-[#f0f0f0] -ml-[6px]' onClick={clickInsideModal}>

//         <div className="mr-4 flex items-center">
//           <Image
//             src={hit.productImages[0].imageURL} alt={hit.name} width="28" height="42"
//           />
//         </div>

//         <div className="flex  items-center justify-center">

//           <Highlight hit={hit} attribute="name"
//             highlightedTagName={'span'}
//             classNames={{
//               highlighted: ' font-semibold',
//               nonHighlighted: 'font-normal',
//             }} />


//           <span className='text-sm '>{hit.brand.name}</span>


//         </div>
//       </a>
//     </Link>
//   );
// }


// interface CollectionsHitProps {
//   clickInsideModal: () => State<unknown, AnyEventObject, any, {
//     value: any;
//     context: unknown;
//   }, MarkAllImplementationsAsProvided<ResolveTypegenMeta<Typegen0, AnyEventObject, BaseActionObject, ServiceMap>>>,
//   hit: any,
//   props?: any,
// }
// function CollectionsHit({ hit, clickInsideModal }: CollectionsHitProps) {
//   if (!hit || !clickInsideModal) {
//     return null
//   }
//   return (
//     <Link href={`/collections/${hit.objectID}`}>
//       <a className='py-3 px-4 bg-[#f0f0f0] text-black text-sm font-semibold rounded-3xl' onClick={clickInsideModal}>{hit.objectID}</a>
//     </Link>
//   );


// }

