import { createMachine, assign, send, spawn } from "xstate";

import { atomWithMachine } from "jotai/xstate"

// export const machine =
//   /** @xstate-layout N4IgpgJg5mDOIC5QGECGBrdACAsqgxgBYCWAdmAHQ7H4BOqADoQPbkUC2ArgC6QDEnUl16JQDZrGLdirUSAAeiALQBGAEwBmCho0AGABxr9AFgCsKi-tMA2ADQgAnsuu612jdZtrj+lQHZTNRUAX2D7NExcAhI2ajpGFjZBYX4UuXFJaVkkBWU1AE5jCmMfYwNTDz8-O0dnV3dPa29fAKDQ8IxsPCIySgA3YggwZgpYFgB3MigsAaHmPhIhmcHh9IkpGVI5RQR9XQp-NQMq02MqnRqnBCUXNwvTfKqVazO-ELCQCK7o3opZ4YopGY3CwY2Yk1I03+8zB42WczWmU220Qahe2l0rm8aj8aI0xg09iuN3q9zeOgJplCHyBQ3gOS+UR6sRo9CYrEoKQgiI22VAO1U1Qo1g0aL8+QpGhUvmMROUbzcniOBV8Ohx+nan06TJilDibMSlGSPEgPKyWxyAs0+QomgeOLRxhcl2UPhUFAqrny+W8+RMOOsmsZ3V1VFZCQ5ZuRluUUv0xVK5Uq1Tl1z8VmFgV0KlM+hMHglQe1Id+0NGEym8NWOQyvIt-MQKjMFF0xhxBN0Gj8unMhlTSjeNr8Xf0+Rzeeszz8RciJbYZaBINhlehUb5uQQKlFLeH3rHxn85j9-eM3uFL28z2z0vUGhn32Z-RWzDX9Y3NgOuOOAVeF374rcYd0yqTx8k8Xx7x1XpXxRa58TcEoTCTawQP7fRPAoID0K7PRrG9adqSAA */
//   createMachine({
//     tsTypes: {} as import("./machine.typegen").Typegen0,
//     states: {
//       Micraphone: {
//         initial: "muted",
//         states: {
//           muted: {
//             on: {
//               unmute: {
//                 actions: "unmute microphone",
//                 target: "unmuted",
//               },
//             },
//           },
//           unmuted: {
//             on: {
//               mute: {
//                 actions: "mute micraphone",
//                 target: "muted",
//               },
//             },
//           },
//         },
//       },
//       video: {
//         initial: "showing video",
//         states: {
//           "showing video": {
//             on: {
//               "hide video": {
//                 target: "not showing video",
//               },
//             },
//           },
//           "not showing video": {
//             on: {
//               "show video": {
//                 target: "showing video",
//               },
//             },
//           },
//         },
//       },
//     },
//     type: "parallel",
//     id: "Cakk Machine",
//   });

// export const count =
//   /** @xstate-layout N4IgpgJg5mDOIC5QBUD2UoBswDoCiAdgIYBG2EOA8gQMRobaKgAOqsAlgC7uoFMgAPRAE4AzADYcABgAsADilyZAJnFLxogIwAaEAE9EogOyTNxowFZNy5cOFHVFgL5Pd9LLkKlyVAGa+6dA9+Vg5uXn4hBHFNYRxNKSNRGQsrYWsLOV0DBFSLHCMpUXTRUrEZIyMXNyDsfGIySBoQti4ePiRBRGUrHHFLDXTlOWUUm2zDROkLGSlhzRMpYTlK6pB3OoARdlhvJoBbIgBrMAACLlOIztC2q9Aoiyl8mJkxTTNZm1EJhBTRHFUoxkmhkohsSRcrhABFQEDg-A2ngaPmoLTC7UiiHEFiMAOBRgWFmUoiJcnEPxJyhwqVm4kS-Qs4lBzihiPqewolH8aNuHXuIgcOAq4nEPSZ4nKyh+dM0OBGyiK2KUwM0cjWbK8jQgPPCfK6uWE+QSSRSaQyWX03SMcRpihSFWEw2EMnVtVw212Wp1GM6US0-wkWhkMSUI1ePxmUhw9kURIsoiUxTVrLd3ru+vBfQGxWsYaJUstCAVkhpCTkYJGYiJkKcQA */
//   createMachine({
//     context: {
//       count: 0,
//     },
//     initial: "Enabled",
//     states: {
//       Enabled: {
//         initial: "On",
//         states: {
//           On: {
//             on: {
//               Toggle: {
//                 actions: "incrimentToggle",
//                 target: "Off",
//               },
//             },
//           },
//           Off: {
//             on: {
//               Toggle: {
//                 actions: "incrimentToggle",
//                 target: "On",
//               },
//             },
//           },
//         },
//         always: {
//           cond: "If Count greater than 5",
//           target: "Disabled",
//         },
//       },
//       Disabled: {
//         on: {
//           "make it on": {
//             actions: "resetToggle",
//             target: "#Toggle.Enabled.On",
//           },
//         },
//       },
//     },
//     id: "Toggle",
//   }, {
//     guards: {
//       "If Count greater than 5": (context, event) => {
//         return context.count > 5;
//       }
//     },
//     actions: {
//       incrimentToggle: assign({
//         count: (ctx, event) => ctx.count + 1,
//       }),
//       resetToggle: assign({
//         count: (ctx, event) => 0,

//       })
//     }
//   })
// export const ShadowMachine =
//   /** @xstate-layout N4IgpgJg5mDOIC5QGUAWBDCB7A7gAgFl0BjVASwDswA6AeQoGIBhAGy1jDzU10VAAd2ZAC5ksFPiAAeiALQA2AOzUArACYAHAGZFGlQBYAnAEYADGa0AaEAE85K49SMr5+g-o1nDhxQF9f1tzY+ESklDS0AGaRDLT8YBRcGMGSgrAiYhJI0nJK1MYa+vKmxWqKWoZGatZ2CGpaKtTyai0qhRpqpp2GGv4BIBRYEHCSQbiEJORUdFkCQqLikjIIslpm1CU98hreLlpr8jWIO9RahWpGpiby24by-oHJ46FTEdGp85lLiMb6yjreYxqG46FQGFRHBAnM76TQXUy6LpqQwPEBjEKTcIfdILWY5Fb7fQbeRbHaGPYHSGyIFE5zyYwqEoA+Rae59IA */
//   createMachine({
//     tsTypes: {} as import("./machine.typegen").Typegen0,
//     initial: "Off",
//     states: {
//       On: {
//         on: {
//           "Close Shadow": {
//             target: "Off",
//           },
//         },
//       },
//       Off: {
//         on: {
//           "Open Shadow": {
//             target: "On",
//           },
//         },
//       },
//     },
//     id: "ShadowMachine",
//   })


export const AutoSearchBoxMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGUwEMBOBjAFgAgCEB7ADwDoARMAMzQFcAbAFz2SbSbAGIBJAOwAOdFsRJ4AwgwCWWANYBtAAwBdRKAFFYUplKJ81IEogCsAJgA0IAJ6IAbGbKnTtgCwBOAOwAOAMyKPbu4AvkGWqJi4hKSUNPTMrOycXOHY+DxYehLScpBKqkggGlo6egZGCGaWNgheAIxk7m5urra1th7etT4hYeipUeQAskQQYAx4UrB4RAJgfJBckppgeMMQaAx5BkXauvoF5S1kHrVevraKxoputS5ViKY+xo6NLvbGAWZexj0gKZGiMhrMYTKYzOYLfhaUasPqRdKZSQyBQqbaaXalA6ILwee4IFymeoeV5NHzfHxdX7-fCA4HjSbTWbzCCLbKyPAAeWE0LAWwKOxK+1AhxcPkczlc3yuNzu1kQPhcz2JTUaZOMFO6oT+cJp0TpoMZEIgZGQOCIAHc8ABbTQsDRCBiYPACDAjOhYJiwVnIvBQqQwtYbPnqdGCsp2Fz1YxvZq1S7XW54r4vJrqik3a4eEJavgjOAGakDNHFPbhhAAWjcePLtjIinriieZJ8TlOJypOoGMVojBYbA4YGLGKFhmxfjItlMV0JtknnlqFjl+PHphJbjVGtsHYiuqGefpYKZkCHYaxCB8VaXpzFq5VpPJlK1hdp+4N4OZJrNlptsDtM0YToum6HrwPyoalmeFIeGQ3yBLUxhNN8LgeD4SaNg0HiuLGLjxh8279C+owHoaH4AGJEFgdBTFIgjCCeEHCvKTgwQhkYIW4SEoXiLg8WQnhYaYHjsYJtRZtmQA */
  createMachine({
    tsTypes: {} as import("./AutoSearchBox.machine.typegen").Typegen0,
    predictableActionArguments: true,
    initial: "Default State",
    states: {
      "Default State": {

        on: {
          "Input Box Click": {
            target: "Model is opened",
          },
          "Search Icon Clicked": {
            target: "Model is opened",
          },
        },
      },
      "Model is opened": {
        entry: [
          "Open Shadow",
          "Open Modal",
          "Focus Input",
        ],
        exit: ["Close Shadow", "Close Modal"],

        initial: "Show most popular products",
        states: {
          "Show most popular products": {
            on: {
              "Click Inside Modal": {
                actions: "Clear Input Field",
                target: "#Search Box.Default State",
              },
            },
          },
          "Focus input": {
            type: "parallel",
          },
        },
        on: {
          "Close Modal": {
            target: "Default State",
          },
          "Inside Search Icon Click": {
            actions: "Focus Input",
            target: ".Focus input",
          },
          "Click Outside": {
            target: "Default State",
          },
        },
      },
    },
    id: "Search Box",
  })
// export const AutoSearchBoxMachine =
//   /** @xstate-layout N4IgpgJg5mDOIC5QGUwEMBOBjAFgAgCEB7ADwDoARMAMzQFcAbAFz2SbSbAGIBJAOwAOdFsRJ4AwgwCWWANaJQAorClMpRPgpAlEAWgCMAFgCsZYwCZjADgAMAZkMA2Gzavn3AGhABPRMedkVsYAnOZGju7B-sEAvjFeqJi4hKSUNPTMrOycXInY+DxYGhLScpBaSipqGlo6CMH6gcY2AOx2lnYtLW76+l6+CA6GZPpW+u6twYZ2dsZtcQno+SnkALJEEGAMeFKweEQCYHyQZMg4RADueAC2yixKQgyYeAIYG3RYTLBckjKyePwVJs8OsIGgGBVlKp1JokNpEIZzC1AsFHMZ2u1HC1HCZ+ogjKZjIYxs1XMZ0Y5HHYFiA8slRGRQVsdnsDkdID8GMowCCNuDIVUYbVEKErIE7DZpkTglYpeY8QhdMFkWNej0TFYGi0aXT8AymdtdvtDscILw+ECebqAUU+CU-gLoTU4XVejZzIEWtYXO19A0cQqImL-IZxqHjPpXNT4rSlvTUgaWcb2WbfnI8AB5YSWx3VWGgOrY4JkMJEoJ2BrE7oK3SORoV7FOcyGUOGFrjRxxGN8DZwLTWhlUWiMFhsDhgXNCl16fQtYutcz2GbBGbN8k1yPDOy9P3TaYYww6uN6hO9w2sk0nM6XG53F4HRjPV7vT7wOGVJ35+EIFqtEuakl9HRVE7CsBUQg9cYpmCaDf3sbUYwHU9NnPZNTTIAAxIgsDoPYpEEYRJ2dAtECsTUyBbGwqXsIDZn0OwFTnGwzHaKZI0mNtO0Q48VkZM8kzZU0iK-OpHCsOx-xlIDaJAsCfEQZtmKgokZiDcwtSPJIT3IY4rlgbIeX0YThXqRxi2bCtlysJjiQVcYlNDcwcWxZwoimTTllEYzp0VRcJIXJdLPaExjA3LoyG3XoxLcdxQy7GIgA */
//   createMachine({
//     tsTypes: {} as import("./AutoSearchBox.machine.typegen").Typegen0,
//     initial: "Default State",
//     states: {
//       "Default State": {
//         on: {
//           "Input Box Click": {
//             target: "Model is opened",
//           },
//           "Search Icon Clicked": {
//             target: "Model is opened",
//           },
//         },
//       },
//       "Model is opened": {
//         entry: [
//           "Open Shadow",
//           "Open Modal",
//           "Focus Input",
//         ],
//         exit: ["Close Shadow", "Close Modal"],

//         initial: "Show most popular products",
//         states: {
//           "Show most popular products": {
//             on: {
//               "Click Inside Modal": {
//                 actions: "Clear Input Field",
//                 target: "#Search Box.Default State",
//               },
//             },
//           },
//           "Focus input": {
//             type: "parallel",
//           },
//         },
//         on: {
//           "Close Modal": {
//             target: "Default State",
//           },
//           "Inside Search Icon Click": {
//             actions: "Focus Input",
//             target: ".Focus input",
//           },
//           "Click Outside": {
//             target: "Default State",
//           },
//         },
//       },
//     },
//     id: "Search Box",
//   })




// export const Twitter =
//   /** @xstate-layout N4IgpgJg5mDOIC5QDkwHcAEAVNYwBcMBZAQwGMALASwDswA6OzWfE-MDARnpz0NoAOAV0IUSsDADcSAGyFgAxAGUwNCBny4CiUAID2sKvip6aOkAA9EAWgBsnAAz0A7AGYArLc+d3AFl-uAEy2ADQgAJ42gdH0ts6+rq5uvgCctinRgQC+WWGomLwExOTUdIzoGCxsHNyF-DTCouJSsvIKAIIQ6pp8GnoaFABOYCQQ5vqGxqbmVgjW7pzO9JwZnK5xgb4OgSmcYZFzOy6BDr6cPg4AHK4Oca7ZuSD52FqEpJS0DEyVrOxcPK8MIIRBgxBJpHJFAAFYawCQAUVgZBIAg4AGswAddAYjCYzEhLDYvE5bA53DdApcdolAvsbOd6Fczs4VpdOL5NmtfDk8hU6sUPmVvlU-twVGpaFAXnwFBBTAxaJI9Bj6M9+e9Sl8KiKavRxRBJdKCAhFXpkVMaABtBwAXXGOItM3pZMusV8VMSlwcay8zjpcz87no0TOFy9AWcKR5Tz5gI1n3KzF+uv1hrqCjAg0GekG9AEMjYADMcwBbVWx3rxoXa5P-VM0KV1E00JXmvHWu0Eia46YE2bWAIpeiXPzrbYpNLuZzxf3WTaBZbxRKuS5sjzcx5quMlBPC2vcAAiVCRJEG6ibRD0EDAMgwAHlUTQFABhEg0Mg3+2TPFOgMOJzRCOwS7Jcg6bLODJMosrLspyrgbryBTboKWpJtU-xHieZ5GvgCCXtet4PqoCiYciZ5fj2+KgP2awrssoYpFOI7wUE-pHO4twJGsziBCxKQJNGW6Vju1ZoaKChYOEqJAjQOEYAAkg0IgUY6fbOr4Sz2Byly2PB8TBO4s6cvQ5KOEkpKcFSjiXDkjw0FecDmEJRRVqhPzoWKIyDJQMmNECEhgCWAj4FiIDdqp1H0pSQ5eO4E5UtElmsRENgBK6vi2IEgYJIO7juIJFYuSJbk6v8-LAk04KtGAKk-mpcwsul2w+GkvqcLYlyzvx9CuBkZwaVOsE+AVSHCShibuaKeqqAaDY4bVvaRXMcTcLx7rxOSU7OGSs7+K4yynJZGS6d6fgjXJrkTaVh7HmR56vHhDmEY+C1UYSDV+MGzhxN6Dj3O1Hj+u4rq6ROgQte6K7Tud6rFVd+6vb+1g+gu7LnIxzjMQEtIpctNzBpyVzwfx4MPIhF1w1gQwjOo+GyPeL1dg6dVLXOeX0KsLLOHllL-hls72PQHKcaS5JnP9+WboVbzFYj9XIyutj0ejTFi8lBzWNt+0hpwOxXPxsG2VkQA */
//   createMachine({
//     initial: "new state 1",
//     states: {
//       "new state 1": {
//         initial: "Search input is empty",
//         states: {
//           "Search input is empty": {},
//           "Tweet input has value": {
//             on: {
//               "Send tweet": {
//                 target: "Sending Tweet",
//               },
//               "Add tweet to thread": {
//                 target: "#New Tweet Machine.Thread Modal Open",
//               },
//               "Press Escape key": {
//                 target: "Discard Tweet:Model Open",
//               },
//             },
//           },
//           "Sending Tweet": {
//             invoke: {
//               src: "Save tweets to database",
//               onDone: [
//                 {
//                   actions: "Clear tweets from local state",
//                   target: "Search input is empty",
//                 },
//               ],
//               onError: [
//                 {
//                   actions: "Show error",
//                   target: "Tweet input has value",
//                 },
//               ],
//             },
//           },
//           "Discard Tweet:Model Open": {
//             on: {
//               Cancel: {
//                 target: "Tweet input has value",
//               },
//               Discard: {
//                 actions: "Clear tweets from local state",
//                 target: "Search input is empty",
//               },
//             },
//           },
//         },
//         on: {
//           "Type in Tweet Input": [
//             {
//               actions: "Save tweets in local state",
//               cond: "Value has length greater than 0",
//               target: ".Tweet input has value",
//             },
//             {
//               cond: "Save tweets in local state",
//               target: ".Search input is empty",
//             },
//           ],
//         },
//       },
//       "Thread Modal Open": {},
//     },
//     id: "New Tweet Machine",
//   })