import { Typegen0 } from "@lib/machine.typegen";
import Link from "next/link";
import { AnyEventObject, BaseActionObject, MarkAllImplementationsAsProvided, ResolveTypegenMeta, ServiceMap, State } from "xstate";

import React, { useEffect } from 'react'
import Image from "next/image";
import { Highlight, useHits, UseHitsProps } from "react-instantsearch-hooks-web";
import { useAutoSearchBoxActions } from "@lib/machines/AutoSearchBoxMachine/useAutoSearchBox";
import { atom, useAtom } from "jotai";



export const productEmpty = atom(false)

const transformItems: UseHitsProps['transformItems'] = (items, { results }) => {



    return items
};

export default function AutoCompleteProductsHit(props: UseHitsProps) {

    const hits = useHits({
        transformItems,

    });

    const {
        clickOutside,
        closeModal,
        clickInsideModal,
        clickSearchIcon,
        inputBoxClick,
        clickSearchIconInside,
    } = useAutoSearchBoxActions()


    const [isProductEmpty, setIsProductEmpty] = useAtom(productEmpty)
    useEffect(() => {
        if (hits.hits.length === 0) {
            setIsProductEmpty(true)
        } else {
            setIsProductEmpty(false)
        }
    }, [hits.hits.length, setIsProductEmpty])

    return (

        <ul className="w-full ">
            {hits?.hits?.map((hit, index) => {
                return (
                    <li key={`${hit}-${index}`} className="-ml-[14px] mr-5  w-full">

                        <Link href={`/products/${hit.objectID}`} >
                            <a
                                onClick={clickInsideModal}
                                className='cursor-pointer 
                            flex rounded-md items-center 
                            py-2 pl-[14px]  hover:bg-[#f0f0f0] 
                            -ml-[6px]' >

                                <div className="mr-4 flex items-center">
                                    {/* @ts-ignore:next-line */}
                                    <Image src={hit.productImages[0].imageURL} alt={hit.name} width="28" height="42"
                                    />
                                </div>

                                <div className="flex  items-center justify-center">

                                    <Highlight hit={hit} attribute="name"
                                        highlightedTagName={'span'}
                                        classNames={{
                                            highlighted: ' font-semibold',
                                            nonHighlighted: 'font-normal',
                                        }} />

                                    {/* @ts-ignore:next-line */}
                                    <span className='text-sm '>{" - "}{hit.brand.name}</span>


                                </div>
                            </a>
                        </Link>
                    </li>
                )


            })}
        </ul>
    )
}




