import { Typegen0 } from "@lib/machine.typegen";
import { useAutoSearchBoxActions, useAutoSearchBoxMatches } from "@lib/machines/AutoSearchBoxMachine/useAutoSearchBox";
import { atom, useAtom } from "jotai";
import Link from "next/link";
import { useEffect } from "react";
import { useHits, UseHitsProps } from "react-instantsearch-hooks-web";
import { AnyEventObject, BaseActionObject, MarkAllImplementationsAsProvided, ResolveTypegenMeta, ServiceMap, State } from "xstate";

export const collectionEmpty = atom(false)

// interface CollectionsHitProps {
//     clickInsideModal: () => State<unknown, AnyEventObject, any, {
//         value: any;
//         context: unknown;
//     }, MarkAllImplementationsAsProvided<ResolveTypegenMeta<Typegen0, AnyEventObject, BaseActionObject, ServiceMap>>>,
//     hit: any,
//     props?: any,
// }


const transformItems: UseHitsProps['transformItems'] = (items, { results }) => {


    return items
};

export default function AutoCompleteCollectionHit() {
    const hits = useHits({
        transformItems,

    });


    const {
        clickOutside,
        closeModal,
        clickInsideModal,
        clickSearchIcon,
        inputBoxClick,
        clickSearchIconInside,
    } = useAutoSearchBoxActions()

    
    const [isCollectionEmpty, setIsCollectionEmpty] = useAtom(collectionEmpty)
    useEffect(() => {
        if (hits.hits.length === 0) {
            setIsCollectionEmpty(true)
        } else {
            setIsCollectionEmpty(false)
        }
    }, [hits.hits.length, setIsCollectionEmpty])


    return (

        <ul className=" w-full h-fit flex gap-2 flex-wrap py-2">

            {hits?.hits?.map((hit: any, index) => {


                return (
                    <li key={`${hit}-${index}`} className="h-full flex bg-yellow-400">
                        <Link href={`/collections/${hit.slug}`}>

                            <a onClick={clickInsideModal} className='py-3 px-4 bg-[#f0f0f0]
                             text-black text-sm font-semibold rounded-3xl
                             '>{hit.name}</a>
                        </Link>
                    </li>
                )


            })}
        </ul>
    );


}

