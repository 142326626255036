import type { PropsWithChildren } from 'react'
import { useMediaQuery } from 'react-responsive'

import screensConfig from "./../config/screens"
import { useWindowSize } from './hooks/useWindowSize'

type BreakpointProps = PropsWithChildren

function parseScreens(screens: Record<string, any>) {
    const screensParsed: Record<string, number> = {}

    for (const screenName in screens) {
        if (Object.prototype.hasOwnProperty.call(screens, screenName)) {
            const screenBreakpoint = (screens as any)[screenName]
            const screenValue = parseInt(screenBreakpoint, 10)
            if (!isNaN(screenValue)) {
                screensParsed[screenName] = screenValue
            }
        }
    }

    return screensParsed
}

const { tablet, laptop, phoneFin } = parseScreens(screensConfig)

// export function useMobileSize() {

//     const { width } = useWindowSize()
// const isThatShit =useMediaQuery({ maxWidth: width })
//     if (!width) {
//         return false
//     } else {
//         return useMediaQuery({ maxWidth: width - 1 })
//     }

// }

//use hooks for boolean answers
//example: isMobile = useMobileMediaQuery()


export function useMobileMediaQuery() {
    return useMediaQuery({ maxWidth: phoneFin })
}
export function usePhoneFinMediaQuery() {
    return useMediaQuery({ maxWidth: tablet })
}

export function useTabletMediaQuery() {
    return useMediaQuery({ maxWidth: laptop })
}

export function useLaptopMediaQuery() {
    return useMediaQuery({ minWidth: laptop })
}

//use these components wrapping devices
//example: isMobile = useMobileMediaQuery()

export function Mobile({ children }: BreakpointProps) {
    const isMobile = useMobileMediaQuery()
    return isMobile ? <>{children}</> : null
}

export function PhoneFin({ children }: BreakpointProps) {
    const isPhoneFin = usePhoneFinMediaQuery()
    return isPhoneFin ? <>{children}</> : null
}

export function Tablet({ children }: BreakpointProps) {
    const isTablet = useTabletMediaQuery()
    return isTablet ? <>{children}</> : null
}

export function Laptop({ children }: BreakpointProps) {
    const isLaptop = useLaptopMediaQuery()
    return isLaptop ? <>{children}</> : null
}
