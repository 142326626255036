import { Heart, Menu, Search } from "@components/icons";
import { Logo } from "@components/ui";
import Image from "next/image";

import Link from "next/link";
import { FC, useEffect, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch-hooks-web";

import AccountOverlay from "./AccountOverlay";

import NavbarRoot from "./NavbarRoot";
import ShoppingBagOverlay from "./ShoppingBagOverlay";


import algoliasearch from "algoliasearch/lite";
import { AutoComplete, menuShadowState, searchMenuState } from "./AutoComplete/AutoComplete";
import { atom, useAtom } from "jotai";
import useLockedBody from "@lib/hooks/useLockedBody";
import MobileSearch from "./MobileSearch";
import { useWindowSize } from "@lib/hooks/useWindowSize";

import { useActor, useMachine } from "@xstate/react";
import { useAutoSearchBoxActions, useAutoSearchBoxMatches } from "@lib/machines/AutoSearchBoxMachine/useAutoSearchBox";
import { useMobileMediaQuery } from "@lib/media";


interface IHeader {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  // data: meQuery | undefined;
  navigation: {
    categories: {
      id: string;
      name: string;
      featured: {
        name: string;
        href: string;
        imageSrc: string;
        imageAlt: string;
      }[];
      sections: {
        id: string;
        name: string;
        items: {
          name: string;
          href: string;
        }[];
      }[];
    }[];
    pages: {
      name: string;
      href: string;
    }[];
  };
}

const client = algoliasearch(
  "MPJ5JMQA9F",
  "275895629962e01ea53d19aee023ff1a"
);

export const mobileSearchHeader = atom(false)

const Navbar: FC<IHeader> = ({ setOpen, open, navigation }) => {

  const {
    clickOutside,
    closeModal,
    clickInsideModal,
    clickSearchIcon,
    inputBoxClick,
    clickSearchIconInside,
  } = useAutoSearchBoxActions()


  const {
    isModelOpen,
    displayMenuItems,
    isDefaultState,
  } = useAutoSearchBoxMatches()


  const isMobile = useMobileMediaQuery()
  const isTablet = useMobileMediaQuery()




  const [isMobileSearch, setIsMobileSearch] = useAtom(mobileSearchHeader);

  const [index1, setIndex1] = useState<any>(null);

  const [clicked, setClicked] = useState(false);

  const [isDark, setIsDark] = useState(false);

  const HandleOpenCategory = (index: number) => {
    setIndex1(index);
    setClicked(false);
  };
  const HandleCloseCategory = (index: number) => {
    setIndex1(null);
    setClicked(false);
  };



  const handleMobileSearchButton = () => {
    setIsMobileSearch(true)
  }

  useEffect(() => {
    if (index1 === null) {
      setIsDark(false);
    } else {
      setIsDark(true);
    }
  }, [index1]);

  // console.log("dark", isDark);

  // function Hit({ hit }) {
  //   return JSON.stringify(hit)
  // }

  const handleDisplay = (index: any, index1: any): boolean => {
    if (index1 === index) {
      setTimeout(() => {
        return true;
      }, 1000);
    }
    return false;
  };




  useLockedBody(isModelOpen || isMobileSearch)


  // const {
  //   isModelOpen } = useAutoSearchBoxMatches()

  // console.log("isModelOpen", isModelOpen)

  return (
    <NavbarRoot>
      <header
        className=" w-full shadow-sm  border-b border-[#DDDDDD]} bg-[#fff]  z-100"
      >
        {/* Header Logo Part */}
        <div className=" flex relative items-center justify-between  h-14">
          <button
            type="button"
            className="bg-white p-2 rounded-md text-gray-400 tablet:hidden"
            onClick={() => setOpen(!open)}
          >
            <span className="sr-only">Open menu</span>
            <Menu className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="hidden tablet:block">
            {/* <Link href="/">
              <a>Düğün Planla
              </a>
            </Link> */}
          </div>
          <div className="absolute inset-x-1/2 transform -translate-x-1/2  top-1 w-fit h-fit pt-1">
            <Link href="/">
              <a>
                <Logo />
              </a>
            </Link>
          </div>

          <div className="flex gap-x-2 justify-center items-center mx-4 relative">


            <div className="  ">

              <div className=" z-[200] ">
                <AutoComplete />
              </div>
            </div>
            <div className="hidden tablet:block z-50">
              <AccountOverlay />
            </div>
            <button className=" tablet:hidden" onClick={inputBoxClick}>
              <div className="pr-2 ">
                <Search className="h-6 w-6 ml-0.5 text-[#333] hover:opacity-70 " />
              </div>
            </button>
            <div className="hidden tablet:block">
              <div className="group flex justify-center items-center relative hover:bg-[#efefef] rounded-full p-2  ">
                <Link href="/">
                  <a>
                    {" "}
                    <Heart className="h-6 w-6 ml-0.5 text-[#333] hover:opacity-90" />
                  </a>
                </Link>
              </div>
            </div>
            <ShoppingBagOverlay />
          </div>
        </div>

        {/* Modal Shadow */}

        {isModelOpen && <div onClick={clickOutside} className="block absolute  bg-[#0006]  w-[100vw] h-[100vh]  z-[101]"></div>}
        {/* Header Menu List Part */}

        <div className="header__Menu hidden tablet:block" >
          <nav className=" relative flex ">
            <ul className="flex w-full justify-center overflow-x-auto scroll-smooth border-b-1  border-t-[#EEEEEE] ">
              {navigation.categories.map((categories, index) => {
                return (
                  <li
                    className="flex flex-col  h-auto  "
                    key={index}
                    onMouseEnter={() => HandleOpenCategory(index)}
                    onMouseLeave={() => HandleCloseCategory(index)}
                  >
                    <div
                      // href="yeni"
                      className="flex group relative cursor-pointer w-fit h-fit z-100  px-6 py-2"
                    >
                      <span className="text-[#333] uppercase font-normal text-sm">
                        {categories.name}
                      </span>
                      <span
                        className={`${index === index1 ? "opacity-100 " : ""
                          } opacity-0 transition-opacity  bg-black w-7 h-1 bottom-0 absolute inset-x-1/2 transform -translate-x-1/2`}
                      ></span>
                    </div>
                    {/* buradakisıyla oynayarak menüyü düzeltebilrisin */}
                    <div
                      hidden={handleDisplay(index, index1) || clicked}
                      className={`${index === index1 ? "opacity-100 " : " opacity-0  hidden"
                        } delay-200 absolute left-0 top-8 overflow-y-auto mt-1 bg-white  max-h-screen w-full transition-all border-b-1 border-b-[#EEEEEE] border-t border-t-[#EEEEEE] `}
                    >
                      <div className="nav menu w-full  flex overflow-hidden ">
                        <ul className="nav menu blocks bg-white  w-full flex mx-8 gap-x-8 justify-start  p-4">
                          <div className="image mt-2">
                            <Link
                              href={
                                categories.featured[0] === undefined
                                  ? "null"
                                  : categories.featured[0].href
                              }
                            >
                              <a onClick={() => setClicked((prev) => !prev)}>
                                <Image
                                  layout="intrinsic"
                                  width={360}
                                  height={288}
                                  src={
                                    categories.featured[0] === undefined
                                      ? "null"
                                      : categories.featured[0].imageSrc
                                  }
                                  alt={
                                    categories.featured[0] === undefined
                                      ? "null"
                                      : categories.featured[0].imageAlt
                                  }
                                />
                                {/* <span>
                                  {categories.featured[0] === undefined
                                    ? "null"
                                    : categories.featured[0].name}
                                </span> */}
                              </a>
                            </Link>
                          </div>
                          {categories.sections.map((section) => (
                            <li key={section.id} className="w-56">
                              <div className="mb-2">
                                <span className="text-[#333] border-b flex grow  border-b-gray-500 font-semibold uppercase text-sm ">
                                  {section.name}
                                </span>
                              </div>
                              {section.items.map((item) => (
                                <ul key={item.name}>
                                  <li>
                                    <Link href={item.href}>
                                      <a
                                        className="text-[#333] font-normal  text-sm hover:underline"
                                        onClick={() =>
                                          setClicked((prev) => !prev)
                                        }
                                      >
                                        {item.name}
                                      </a>
                                    </Link>
                                  </li>
                                </ul>
                              ))}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>
          {isDark && (
            <div className="block absolute bg-[#0006] backdrop-blur-sm  -z-40  w-full h-screen"></div>
          )}
        </div>
      </header>


      {/* //TODO: */}
      {/* <div className="block phoneFin:hidden">
        {isModelOpen && <MobileSearch />
        }
      </div> */}
      {/* {<MobileSearch />} */}

    </NavbarRoot>
  );
};

export default Navbar;
