import "../styles/globals.css";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { useApollo } from "../lib/apollo";
import { ApolloProvider } from "@apollo/client";
import { FC, ReactNode } from "react";
import { Head } from "@components/common";
import "keen-slider/keen-slider.min.css";
import "react-day-picker/lib/style.css";

interface INoop {
  children: ReactNode;
}
const Noop: FC<INoop> = ({ children }) => <>{children}</>;
function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  const Layout = (Component as any).Layout || Noop;
  return (
    <ApolloProvider client={apolloClient}>
      <SessionProvider session={session}>
        <Head />
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </SessionProvider>
    </ApolloProvider>
  );
}

export default MyApp;
