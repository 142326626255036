import React, { createContext, ReactNode, useContext, useRef } from "react";
import { useMachine } from "@xstate/react";
import { AutoSearchBoxMachine } from "./AutoSearchBox.machine";

interface IAutoSearchBoxMatchesContext {
  isModelOpen: boolean,
  displayMenuItems: boolean,
  isDefaultState: boolean,
  inputRef: React.RefObject<HTMLInputElement>,
}

const deufaltMacthesContext = {

  isModelOpen: false,
  displayMenuItems: false,
  isDefaultState: false,


}

// const AutoSearchBoxMatchesContext = createContext<any>(null);
const AutoSearchBoxMatchesContext = createContext<any>(null);
const AutoSearchBoxActionsContext = createContext<any>(null);

interface IAutoSearchBoxProvider {
  children: ReactNode
}

const AutoSearchBoxProvider = ({ children }: IAutoSearchBoxProvider) => {

  const inputRef = useRef<HTMLInputElement>()


  const [state, send] = useMachine(AutoSearchBoxMachine, {

    actions: {
      "Close Modal": () => {

      },
      "Clear Input Field": () => {
        console.log("Clear Input Field")
        inputRef.current && (inputRef.current.value = '')
      },
      "Close Shadow": () => {

      },
      "Focus Input": () => {
        console.log("Focus Input")
        console.log("inputref", inputRef.current)
        console.log("inputref??", typeof inputRef.current)

        console.log("document.hasFocus()", document.hasFocus())
        inputRef.current?.focus()
      },
      "Open Modal": () => {

      },
      "Open Shadow": () => {

      },
    },

  }

  )
  // const yazCnm2 = state.changed("Model is opened")

  // const yazCnm = state.changed("Search Icon Clicked")

  // console.log("yazcnnm", yazCnm)

  const matches = {
    isModelOpen: state.matches("Model is opened"),
    displayMenuItems: state.matches("Model is opened.Show most popular products"),
    isDefaultState: state.matches("Default State"),
    inputRef
  };

  const actions = {
    clickOutside: () => send("Click Outside"),
    closeModal: () => send("Close Modal"),
    clickInsideModal: () => send("Click Inside Modal"),
    clickSearchIcon: () => send("Search Icon Clicked"),
    inputBoxClick: () => send("Input Box Click"),
    clickSearchIconInside: () => send("Inside Search Icon Click"),

  };




  return <>
    <AutoSearchBoxActionsContext.Provider value={actions}>
      <AutoSearchBoxMatchesContext.Provider value={matches}>
        {children}
      </AutoSearchBoxMatchesContext.Provider>
    </AutoSearchBoxActionsContext.Provider>
  </>

}

const useAutoSearchBoxActions = () => {
  const context = useContext(AutoSearchBoxActionsContext);

  if (context === undefined) {
    throw new Error("uuseAutoSearchBoxActions  must be used within a AutoSearchBoxActions");
  }

  return context;
};

const useAutoSearchBoxMatches = () => {
  const context = useContext(AutoSearchBoxMatchesContext);

  if (context === undefined) {
    throw new Error("useAutoSearchBoxMatches must be used within a AutoSearchBoxMatchesProvider");
  }

  return context;
};
export { AutoSearchBoxProvider, useAutoSearchBoxActions, useAutoSearchBoxMatches };